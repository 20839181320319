import moment from 'moment';
import t from 'react-translate';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import { DATE_FORMAT } from 'athena/components/constants';
import CollapsiblePanel from 'athena/components/collapsible-panel';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import {
  CompletionStatus,
  MentoringProgramEnrollment,
  States,
  StepKey,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import StepItem from './step-item';
import { Step } from './types';
import CompleteProfileButton from './profile-completion/complete-profile-button';
import { isAnyRequiredFieldUnanswered, noRequiredQuestions } from './utils';
import { config } from '@config/pendo.config.json';
import { useMentoringProgramQuestionsAnswers } from 'athena/hooks/use-mentoring-program-questions-answers';
import { ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile-questions';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';

const DAYS_BEFORE_MATCH = 5;

const getUnansweredRequiredQuestions = (questions) => {
  const unansweredRequiredQuestions = questions?.filter(question => (
    question.isRequired && !question.answer
  ));
  return unansweredRequiredQuestions;
}

const getStatus = (states: States, key: string, isSessionCreated: boolean) => {
  let status = states[key]?.status || CompletionStatus.NOT_STARTED;
  if (key === StepKey.PROFILE_COMPLETION) {
    // Set status to NOT_STARTED if the profile review is not started and the profile completion is completed
    if (states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.NOT_STARTED) {
      status = CompletionStatus.NOT_STARTED;
    }
  }
  if (key === StepKey.WAITING_FIRST_SESSION && !isSessionCreated) {
    status = CompletionStatus.NOT_STARTED;
  }
  return status;
};

const getProfileCompletionTitle = (states) => {
  // Show review your profile title if profile_completion is completed
  const title = states[StepKey.PROFILE_COMPLETION]?.status === CompletionStatus.COMPLETED
    ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.REVIEW_YOUR_PROFILE.TITLE()
    : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.COMPLETE_YOUR_PROFILE.TITLE();
  return title;
};

type QuestionsType = {
  questions: MentorshipProgramProfileAnswer[]
};

type StepListProps = {
  showPlanSessionFlyout: (session?: MentorshipProgramSession) => void,
};

const StepList = ({
  showPlanSessionFlyout,
}: StepListProps) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const {
    matchAnnouncementDate,
    mentorshipProgramEnrollment: enrollmentId,
    id: mentorshipProgramId,
  } = mentoringProgram || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(
    state => getMentoringProgramEnrollment(state, enrollmentId),
  );

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const connectionId = parseInt($state.params.connectionId, 10);

  const { states, assignedConnections } = mentorshipProgramEnrollment || {};

  const connection = assignedConnections?.find((connec) => (
    connec.connectionId === connectionId
  )) ?? assignedConnections?.[0];
  const { isSessionCreated } = connection || {};

  const completionDate = matchAnnouncementDate
    ? moment(matchAnnouncementDate)
      .subtract(DAYS_BEFORE_MATCH, 'days')
      .format(DATE_FORMAT)
    : null;

  const formattedMatchAnnouncementDate = matchAnnouncementDate
    ? moment(matchAnnouncementDate).format(DATE_FORMAT)
    : null;
  const isMatchUpcoming = moment(matchAnnouncementDate).isAfter(moment());
  const isMatchPast = moment().isAfter(moment(matchAnnouncementDate));
  const upcomingMatchMessage = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.UPCOMING_MATCH_DATE(
    formattedMatchAnnouncementDate,
  );
  const passedMatchMessage = assignedConnections?.length
    ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.PASSED_MATCH_DATE_WITH_CONNECTION(
      formattedMatchAnnouncementDate,
    )
    : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.PASSED_MATCH_DATE_WITHOUT_CONNECTION();
  const getContentBasedOnMatchAnnouncementDate = () => (isMatchUpcoming ? upcomingMatchMessage : passedMatchMessage);

  const { questions }: QuestionsType = useMentoringProgramQuestionsAnswers({
    type: ProgramProfileQuestionType.ORG_LEVEL,
    mentorshipProgramId,
    enrollmentId,
  });

  const unansweredRequiredQuestions = getUnansweredRequiredQuestions(questions);

  const stepsList: { [key in StepKey]?: Step } = {
    [StepKey.PROFILE_COMPLETION]: {
      title: getProfileCompletionTitle(states),
      content: completionDate
        ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.COMPLETE_YOUR_PROFILE.CONTENT(
          completionDate,
        )
        : null,
      showBadge: true,
      ctaButton: (
        <CompleteProfileButton
          states={states}
        />
      ),
    },
    [StepKey.WAITING_CONNECTION]: {
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.TITLE(),
      content: matchAnnouncementDate
        ? getContentBasedOnMatchAnnouncementDate()
        : null,
    },
    [StepKey.WAITING_FIRST_SESSION]: {
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.TITLE(),
      content: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.CONTENT(),
      ctaButton: (isMatchPast && assignedConnections.length) ? (
        <Button
          size='lg'
          onClick={() => showPlanSessionFlyout()}
          data-qa={config.pendo.athena.mentorshipProgram.participantHome.steps.addFirstSession}
        >
          {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.ADD_SESSION()}
        </Button>
      ) : null,
    },
  };

  return (
    <CollapsiblePanel
      title={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.TITLE()}
      dataQa={config.pendo.athena.mentorshipProgram.participantHome.steps.list}
    >
      <div className='mt-4'>
        {Object.entries(stepsList).map(
          ([key, { title, content, showBadge, ctaButton }]) => {
            return (
              <StepItem
                key={key}
                status={getStatus(states, key, isSessionCreated)}
                title={title}
                content={content}
                showBadge={showBadge}
                ctaButton={ctaButton}
              />
            );
          },
        )}
      </div>
    </CollapsiblePanel>
  );
};

export default StepList;
