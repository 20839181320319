/* @ngInject */
export default function TeamWorkspaceModelService(
  TeamsResources,
  _,
  PostModel,
  DiscussionsManager,
  $timeout,
  $q,
) {
  const TeamWorkspaceModel = function (attributes) {
    const _this = this;

    // data
    _this.teamId = null;
    _this.workspacePosts = [];

    _.extend(_this, attributes);


    // functions
    _this.initialize = initialize;
    _this.getWorkspacePosts = getWorkspacePosts;
    _this.getSingleWorkspacePost = getSingleWorkspacePost;
    _this.getWorkspaceFilterCounts = getWorkspaceFilterCounts;
    _this.getWorkspaceContributions = getWorkspaceContributions;

    _this.createTextPost = createTextPost;

    _this.createMeetingPost = createMeetingPost;
    _this.updateMeetingPost = updateMeetingPost;

    _this.updatePost = updatePost;
    _this.deletePost = deletePost;

    _this.createWorkspaceDocumentPost = createWorkspaceDocumentPost;
    _this.deleteWorkspaceDocument = deleteWorkspaceDocument;
    _this.updateWorkspaceDocumentPost = updateWorkspaceDocumentPost;
    _this.createWorkspaceDocumentPostFromGoogleDrive = createWorkspaceDocumentPostFromGoogleDrive;
    _this.updateWorkspaceDocumentPostFromGoogleDrive = updateWorkspaceDocumentPostFromGoogleDrive;

    _this.createMeetingRsvp = createMeetingRsvp;
    _this.updateMeetingRsvp = updateMeetingRsvp;
    _this.updateMeetingData = updateMeetingData;

    _this.createSubmissionPost = createSubmissionPost;

    _this.prependNewPost = prependNewPost;
    _this.updatePostInPlace = updatePostInPlace;
    _this.deletePostInPlace = deletePostInPlace;
    _this.deleteMeeting = deleteMeeting;


    initialize();


    function initialize() {
      DiscussionsManager.initialize({ catalogId: _this.catalogId, context: 'teamWorkspace' });
    }


    function getWorkspacePosts(params) {
      return TeamsResources.getWorkspacePosts(_.extend({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
      }, params)).$promise.then((response) => {
        const posts = _.map(response.result, (item) => PostModel.normalizePostData(item, { catalogId: _this.catalogId }));

        if (params.page === 1) {
          _this.workspacePosts = posts;
        } else {
          _this.workspacePosts = _.uniq(_this.workspacePosts.concat(posts), 'id');
        }

        return posts;
      });
    }

    function getSingleWorkspacePost(postId, isDirectLink) {
      return TeamsResources.getSingleWorkspacePost({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
        postId,
      }).$promise.then((response) => {
        const newWorkspacePost = PostModel.normalizePostData(response.result, { catalogId: _this.catalogId });
        if (isDirectLink) {
          _this.workspacePosts = [newWorkspacePost];
        }

        return newWorkspacePost;
      });
    }

    function getWorkspaceFilterCounts() {
      return TeamsResources.getWorkspaceFilterCounts({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
      }).$promise;
    }

    function getWorkspaceContributions(memberId) {
      return TeamsResources.getDiscussionContributions({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
        teamMemberId: memberId,
      }).$promise.then((resource) => resource.result);
    }

    function createTextPost(workspacePostData) {
      return TeamsResources.createTextPost({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
      }, {
        topic: workspacePostData,
      }).$promise.then((response) => {
        const newWorkspacePost = response.result;

        // prepend new post
        prependNewPost(newWorkspacePost);

        return newWorkspacePost;
      }).catch(
        (error) => $q.reject(error),
      );
    }

    function createMeetingPost(workspaceMeetingData) {
      return TeamsResources.createMeetingPost({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
      }, {
        meeting: workspaceMeetingData,
      }).$promise.then((response) => {
        const newMeetingPost = response.result;

        prependNewPost(response.result);
        return newMeetingPost;
      }).catch(
        (error) => $q.reject(error),
      );
    }

    function updateMeetingPost(workspaceMeetingData) {
      return TeamsResources.updateMeetingPost({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
        meetingId: workspaceMeetingData.id,
      }, {
        meeting: workspaceMeetingData,
      }).$promise.then((response) => {
        const updatedMeetingPost = response.result;
        // console.log('in workspace model ', response.result);
        return updatedMeetingPost;
      });
    }

    function prependNewPost(newWorkspacePost) {
      _this.workspacePosts.unshift(PostModel.normalizePostData(newWorkspacePost, { catalogId: _this.catalogId }));
      _this.workspacePosts = _.uniq(_this.workspacePosts, (post) => post.id);
    }

    function updatePostInPlace(updatedWorkspacePost) {
      $timeout(() => {
        const _indexOfPost = _.findIndex(_this.workspacePosts, { id: updatedWorkspacePost.id });
        _this.workspacePosts.splice(_indexOfPost, 1, updatedWorkspacePost);
      });
    }

    function deletePostInPlace(deletedWorkspacePost) {
      const _indexOfPost = _.findIndex(_this.workspacePosts, { id: deletedWorkspacePost.id });
      _this.workspacePosts.splice(_indexOfPost, 1);
    }

    function updatePost(workspacePost) {
      const topic = _.pick(workspacePost, ['forumId', 'title', 'releaseDate', 'starterId', 'body', 'metaContent']);
      return TeamsResources.updateWorkspacePost({
        catalogId: _this.catalogId,
        postId: workspacePost.id,
      }, {
        topic,
      }).$promise.then((response) => {
        let editedPost = _.extend(workspacePost, response.result);
        editedPost = PostModel.normalizePostData(editedPost, { catalogId: _this.catalogId });

        updatePostInPlace(editedPost);

        return editedPost;
      });
    }

    function deletePost(workspacePost) {
      return TeamsResources.deleteWorkspacePost({
        catalogId: _this.catalogId,
        postId: workspacePost.id,
      }).$promise.then(() => {
        deletePostInPlace(workspacePost);

        return workspacePost;
      });
    }

    function deleteMeeting(workspaceMeeting) {
      return TeamsResources.deleteMeetingPost({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
        meetingId: workspaceMeeting.metaContent.id,
      }).$promise.then(() => {
        deletePostInPlace(workspaceMeeting);

        return workspaceMeeting;
      });
    }

    function createWorkspaceDocumentPost(documentPostData) {
      const massagedDocumentPostData = {
        filesize: documentPostData.file.size,
        filename: documentPostData.file.name,
        filetype: documentPostData.file.type,
        uniqueId: documentPostData.file.uniqueId,
        body: documentPostData.body,
        ownerId: _this.teamId,
        ownerType: 'team',
      };

      return TeamsResources.uploadWorkspaceDocument({
        catalogId: _this.catalogId,
      }, massagedDocumentPostData).$promise.then((response) => {
        const newWorkspacePost = response.result;
        prependNewPost(newWorkspacePost);
        return newWorkspacePost;
      }).catch(
        (error) => $q.reject(error),
      );
    }

    function updateWorkspaceDocumentPost(documentPostData) {
      const massagedDocumentPostData = {
        filesize: documentPostData.file.size,
        filename: documentPostData.file.name,
        filetype: documentPostData.file.type,
        uniqueId: documentPostData.file.uniqueId || null,
        id: documentPostData.file.id || null,
        body: documentPostData.body,
        ownerId: _this.teamId,
        ownerType: 'team',
        topicId: documentPostData.postId,
      };

      return TeamsResources.updateWorkspaceDocument({
        catalogId: _this.catalogId,
        fileId: documentPostData.fileId,
      }, massagedDocumentPostData).$promise.then((response) => {
        const updatedDocumentPost = PostModel.normalizePostData(response.result, { catalogId: _this.catalogId });
        updatePostInPlace(updatedDocumentPost);
      });
    }

    function deleteWorkspaceDocument(fileId) {
      return TeamsResources.deleteWorkspaceDocument({
        catalogId: _this.catalogId,
        fileId,
      }, {
        teamId: _this.teamId,
      }).$promise;
    }

    function createWorkspaceDocumentPostFromGoogleDrive(fileData) {
      // var massagedData = _.extend(fileData.file, { externalId: fileData.file.id, id: null });

      return TeamsResources.uploadWorkspaceDocumentFromGoogleDrive(
        {
          catalogId: _this.catalogId,
          teamId: _this.teamId,
        },
        fileData,
      ).$promise.then((response) => {
        const newWorkspacePost = response.result;
        prependNewPost(newWorkspacePost);
        return newWorkspacePost;
      });
    }

    function updateWorkspaceDocumentPostFromGoogleDrive(fileData, externalDocumentId, postId) {
      return TeamsResources.updateWorkspaceDocumentFromGoogleDrive({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
        id: externalDocumentId ? null : fileData.file.id,
        topicId: postId,
      }, fileData).$promise.then((response) => {
        const updatedDocumentPost = PostModel.normalizePostData(response.result, { catalogId: _this.catalogId });
        updatePostInPlace(updatedDocumentPost);
      });
    }

    function createMeetingRsvp(rsvpData) {
      return TeamsResources.createMeetingRsvp({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
        meetingId: rsvpData.meetingId,
      }, {
        userId: rsvpData.userId,
        rsvp: rsvpData.rsvp,
      }).$promise;
    }

    function updateMeetingRsvp(rsvpData) {
      return TeamsResources.updateMeetingRsvp({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
        meetingId: rsvpData.meetingId,
        attendanceId: rsvpData.attendanceId,
      }, {
        rsvp: rsvpData.rsvp,
      }).$promise;
    }

    function updateMeetingData(event) {
      const postWithUpdatedData = _.findWhere(_this.workspacePosts, { id: event.postId });
      _.extend(postWithUpdatedData.metaContent, event.meeting);
      updatePostInPlace(postWithUpdatedData);
    }

    function createSubmissionPost(submissionPostData) {
      return TeamsResources.createSubmissionPost({
        catalogId: _this.catalogId,
        teamId: _this.teamId,
      }, {
        topic: submissionPostData,
      }).$promise.then((response) => {
        prependNewPost(response.result);

        return response.result;
      }).catch(
        (error) => $q.reject(error),
      );
    }
  };

  return TeamWorkspaceModel;
}
