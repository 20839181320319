import { css } from '@emotion/react';
import { novoEdBlack, novoEdBlue } from 'athena/styles/colors';
import ClickableContainer from 'components/clickable-container';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import { white } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

type CollapsiblePanelProps = {
  title: string;
  children: React.ReactElement;
  iconSize?: string;
  dataQa?: string;
  dataQaId?: string;
  isNested?: boolean;
};

const styles = (isNested?: boolean) => css`
  border-radius: 10px;
  padding: 24px ${standardSpacing}px;
  background-color: ${white};

  .collapsible-panel-header {
    cursor: pointer;
    color: ${novoEdBlack};
  }

  ${isNested
    ? css`
        padding: 0;
        .collapsible-panel-header {
          font-size: ${textSmallFontSize}px;
        }
      `
    : css`
        &:hover {
          box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
        }
        .collapsible-panel-header {
          &:hover {
            color: ${novoEdBlue};
          }
        }
      `}
`;

const CollapsiblePanel = ({ title, children, iconSize = 'small', dataQa, dataQaId, isNested = false }: CollapsiblePanelProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <div css={styles(isNested)} className='collapsible-panel'>
      <ClickableContainer
        className='heading-5 collapsible-panel-header d-flex align-items-center justify-content-between'
        onClick={() => setIsExpanded(currentState => !currentState)}
        data-qa={dataQa}
        data-qa-id={dataQaId}
      >
        <span className='text-truncate'>{title}</span>
        <NvIcon icon={isExpanded ? 'arrow-up' : 'arrow-down'} size={iconSize} />
      </ClickableContainer>
      <Collapse in={isExpanded}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsiblePanel;
