import store from 'redux/store';
import t from '../../../react-translate';
import LiveEventLectureComponent from '../../../live_sessions/components/live-session-lecture-component';
import ZoomReAuthenticate from '../../../institutions/components/advanced-settings/integrations/zoom-integration';

export default {
  bindings: {
    editMode: '<',
    index: '<',
    lectureComponent: '<',
    reorderMode: '<',
    context: '<',
    linkedEditMode: '<',
  },
  controller: function ctrl(
    $controller,
    $stateParams,
    $timeout,
    $uibModal,
    AlertMessages,
    CurrentPermissionsManager,
    CurrentUserManager,
    InstitutionsManager,
    RailsRoutes,
    ReactLecturePageContext,
    config,
    $scope,
    ConfirmationOverlays,
    CurrentCourseManager,
    nvUtil,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;
    vm.defaultGetPayload = vm.lectureComponent.getPayload;
    vm.notAuthenticated = vm.lectureComponent.liveSession.sessionType !== vm.lectureComponent.constructor.SESSION_TYPE_EXTERNAL;
    let lastSavedDescription = vm.lectureComponent.liveSession.description;
    let lastSavedExpectations = vm.lectureComponent.liveSession.mainDescription;
    vm.InstitutionsManager = InstitutionsManager;
    vm.isAdmin = CurrentUserManager.isAdmin();
    vm.integrationSettings = {};
    vm.isZoomNeedsReAuthentication = false;
    vm.creatorId = vm.lectureComponent?.liveSession?.creatorUserId;

    vm.showZoomReAuthenticate = () => {
      vm.creatorId = vm.lectureComponent.liveSession.creatorUserId;
      vm.source = vm.integrationSettings.ZoomLiveSession?.source;

      return vm.lectureComponent.liveSession?.sessionType === 'ZoomLiveSession'
        && CurrentPermissionsManager.hasCourseAdminPermissions()
        && vm.integrationSettings?.ZoomLiveSession?.needsReAuthentication;
    };

    $scope.ZoomReAuthenticateComponent = () => (
      <ZoomReAuthenticate
        isLecturePage
        authenticatedUser={vm.integrationSettings?.ZoomLiveSession?.authenticatedUser}
        integrationType={vm.source ? nvUtil.toSnakeCase(vm.source) : ''}
      />
    );

    $scope.LiveEventLectureComponent = () => (
      <LiveEventLectureComponent
        lectureComponent={vm.lectureComponent}
        isEdit={vm.editMode || vm.linkedEditMode}
        onSaveExpectations={vm.onSaveExpectations}
        register={vm.register}
        joinNow={vm.joinLiveSession}
        unregister={vm.unregister}
        uploadAttendeeList={vm.uploadAttendees}
        uploadRecording={vm.uploadRecording}
        delete={vm.deleteSession}
        editRecording={vm.editRecording}
        removeRecording={vm.removeRecording}
        recordingEventCallback={vm.lectureComponent.liveSession.recordingEventCallback}
      />
    );
    // Authenticating Live Session Settings
    if (CurrentPermissionsManager.isCourseBuilder() || CurrentPermissionsManager.isInstructor()) {
      CurrentUserManager.currentUserCourse.getLiveSessionSettings().then((response) => {
        if (vm.creatorId === CurrentUserManager.user.id || response.ZoomLiveSession?.source === 'zoomAccountOauth') {
          vm.integrationSettings = response;
          vm.isZoomNeedsReAuthentication = response.ZoomLiveSession?.needsReAuthentication;
        }
        if (vm.lectureComponent.liveSession.sessionType !== vm.lectureComponent.constructor.SESSION_TYPE_EXTERNAL) {
          vm.notAuthenticated = response.authenticatedStatus === 'not_authenticated';

          if (vm.lectureComponent.liveSession.sessionType === 'ZoomLiveSession') {
            vm.notAuthenticated = !response?.ZoomLiveSession?.authenticated;
          }
          if (vm.lectureComponent.liveSession.sessionType === 'TeamsLiveSession') {
            vm.notAuthenticated = !response?.TeamsLiveSession?.authenticated;
          }
        }
        if (vm.lectureComponent.liveSession.sessionType === 'ZoomLiveSession' && vm.lectureComponent.liveSession.hasAuthError) {
          if (response.ZoomLiveSession?.source === 'zoomAccountOauth' && CurrentPermissionsManager.hasOrgAdminPermissions()) {
            AlertMessages.error('', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.ZOOM_CURRENT_USER', {}, {}, Infinity, {
              text: 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.AUTHENTICATE',
              href: RailsRoutes.accountZoomOauthUrl(InstitutionsManager.institution.id),
            });
          } else if (vm.lectureComponent.liveSession.creatorUserId === CurrentUserManager.user.id) {
            AlertMessages.error('', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.ZOOM_CURRENT_USER', {}, {}, Infinity, {
              text: 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.AUTHENTICATE',
              href: RailsRoutes.zoomOAuthInstallationUrl($stateParams.catalogId, $stateParams.id),
            });
          } else {
            AlertMessages.error('', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.ZOOM_OTHER_USER', {}, {}, Infinity);
          }
        }
        if (vm.lectureComponent.liveSession.sessionType === 'TeamsLiveSession' && vm.lectureComponent.liveSession.hasAuthError) {
          if (vm.lectureComponent.liveSession.creatorUserId === CurrentUserManager.user.id) {
            AlertMessages.error('', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.TEAMS_CURRENT_USER', {}, {}, Infinity, {
              text: 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.AUTHENTICATE',
              href: RailsRoutes.msTeamOAuthInstallationUrl($stateParams.catalogId, $stateParams.id),
            });
          } else {
            AlertMessages.error('', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.TEAMS_OTHER_USER', {}, {}, Infinity);
          }
        }
      });
      if (vm.creatorId !== CurrentUserManager.user.id) {
        CurrentUserManager.currentUserCourse.getLiveSessionSettings(vm.creatorId).then((response) => {
          vm.integrationSettings = response;
          vm.isZoomNeedsReAuthentication = response.ZoomLiveSession?.needsReAuthentication;
          if (vm.isZoomNeedsReAuthentication && vm.integrationSettings.ZoomLiveSession?.createdLiveSessions?.hasCreatedLiveSessions) {
            AlertMessages.oAuthError(
              '',
              'INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.OAUTH_MESSAGE',
              {},
              { user: `${vm.integrationSettings?.ZoomLiveSession?.authenticatedUser?.firstName} ${vm.integrationSettings?.ZoomLiveSession?.authenticatedUser?.lastName}` },
              Infinity,
              {},
              () => {},
              'zoom-user-level-reauth',
            );
          }
        });
      }
    }

    vm.deleteSession = (sessionId, onSuccess) => {
      const modalInstance = ConfirmationOverlays.openConfirmationModal(
        'live_sessions/templates/deletion-warning-overlay.html',
        'AttachModalResolvesToVmCtrl as vm', {
          vmResolves: () => ({
            sessionId,
            course: CurrentCourseManager.course,
          }),
        },
      );

      modalInstance.result.then(() => {
        vm.lectureComponent.liveSession.deleteSession(sessionId).then(() => {
          AlertMessages.success('FORM.SUCCESS_BANG', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.DELETE_SESSION_SUCCESS');
          ReactLecturePageContext.updateLecturePage();
          store.dispatch({
            type: 'UPDATE_LECTURE_PAGE',
          });
        }).catch(() => {
          AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        });
      });
    };

    vm.uploadAttendees = (sessionId = null) => {
      $uibModal.open({
        templateUrl: 'lecture_pages/templates/components/upload-live-session-attendees-modal.html',
        controller: 'UploadLiveSessionAttendeesModalController as vm',
        windowClass: 'discussions-modal',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          validateEmails: () => vm.lectureComponent.liveSession.validateEmails.bind(vm.lectureComponent.liveSession),
          uploadAttendance: () => vm.lectureComponent.liveSession.uploadAttendance.bind(vm.lectureComponent.liveSession, sessionId),
        },
      });
    };

    vm.uploadRecording = (sessionId, events = {}) => {
      const {
        onFileSelected = null,
        onFileLoaded = null,
        onSuccess = null,
        onCloseModal = null,
      } = events;
      $uibModal.open({
        templateUrl: 'lecture_pages/templates/components/upload-live-session-recording-modal.html',
        controller: 'UploadLiveSessionRecordingModalController as vm',
        windowClass: 'discussions-modal',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          setRecording: () => vm.lectureComponent.setRecording,
          onFileLoaded: () => onFileLoaded,
          onFileSelected: () => onFileSelected,
          onSuccess: () => onSuccess,
          manualUploadRecording: () => vm.lectureComponent.liveSession.manualUploadRecording.bind(vm.lectureComponent.liveSession, sessionId),
          setEditMenu: () => vm.setEditMenu,
          onCloseModal: () => onCloseModal,
        },
      });
    };

    vm.editRecording = (sessionId = null, file = null, onSuccess = null) => {
      const session = vm.getSession();
      $uibModal.open({
        templateUrl: 'lecture_pages/templates/components/live-session-edit-recording-modal.html',
        controller: 'IndividualVideoEditCtrl as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet large-modal individual-video-edit-modal',
        resolve: {
          recording: file ?? session.recording.file,
          liveSessionId: () => sessionId ?? session.id,
          lectureVideo: null,
          onSuccessfulEdit: () => onSuccess,
        },
      }).result.then(() => {
        vm.lectureComponent.liveSession.preprocessRecording();
      });
    };

    vm.removeRecording = (sessionId, onSuccess) => {
      vm.lectureComponent.liveSession.manualUploadRecording(sessionId).then(() => {
        vm.setEditMenu();
        onSuccess?.();
      });
      vm.lectureComponent.setRecording();
    };

    vm.saveDescriptionOnBlur = () => {
      const isMultisession = vm.lectureComponent.liveSession.sessions?.length > 1;
      const session = isMultisession
        ? vm.lectureComponent.liveSession.sessions?.[0]
        : vm.lectureComponent.liveSession;
      if (lastSavedDescription !== session.description
        || lastSavedDescription !== vm.lectureComponent.liveSessionDraft.sessions[0].description
      ) {
        lastSavedDescription = session.description;
        vm.lectureComponent.liveSessionDraft.mainDescription = lastSavedDescription;
        vm.lectureComponent.liveSession.mainDescription = lastSavedDescription;
        vm.lectureComponent.getPayload = vm.defaultGetPayload;
        if (!isMultisession && vm.lectureComponent.liveSession.sessions?.length) {
          vm.lectureComponent.liveSession.sessions[0].description = session.description;
          vm.lectureComponent.liveSessionDraft.sessions[0].description = session.description;
        }
        vm.lectureComponent.save();
      }
    };

    vm.saveTitle = () => {
      const isMultisession = vm.lectureComponent.liveSession.sessions?.length > 1;
      const session = isMultisession
        ? vm.lectureComponent.liveSession.sessions?.[0]
        : vm.lectureComponent.liveSession;
      vm.lectureComponent.liveSessionDraft.mainTitle = session.title;
      vm.lectureComponent.liveSession.mainTitle = session.title;
      vm.lectureComponent.getPayload = vm.defaultGetPayload;
      if (!isMultisession && vm.lectureComponent.liveSession.sessions?.length) {
        vm.lectureComponent.liveSession.sessions[0].title = session.title;
        vm.lectureComponent.liveSessionDraft.sessions[0].title = session.title;
      }
      vm.saveTitleOnBlur();
    };

    vm.saveDraftTitleOnBlur = () => {
      vm.lectureComponent.liveSessionDraft.title = vm.lectureComponent.liveSession.title;
    };

    vm.saveDraftMainTitleOnBlur = () => {
      vm.lectureComponent.liveSessionDraft.mainTitle = vm.lectureComponent.liveSession.mainTitle;
    };

    vm.saveMainTitle = () => {
      vm.lectureComponent.liveSessionDraft.mainTitle = vm.lectureComponent.liveSession.mainTitle;
      vm.lectureComponent.save();
    };

    vm.onSaveExpectations = (expectations) => {
      if (expectations !== lastSavedExpectations) {
        vm.lectureComponent.liveSession.mainDescription = expectations;
        vm.lectureComponent.liveSessionDraft.mainDescription = expectations;
        lastSavedExpectations = expectations;
      }
      vm.lectureComponent.save();
    };

    vm.joinLiveSession = (sessionId) => {
      const session = vm.getSession();
      vm.lectureComponent.liveSession.recordJoinIntention(sessionId ?? session.id).then(() => {
        ReactLecturePageContext.updateLecturePage();
      });
    };

    vm.hasMultipleSessions = () => vm.lectureComponent.liveSession?.sessions?.length > 1;

    vm.setEditMenu = () => {
      const { sharedProps, setSharedProps } = this.context;
      const extraOptions = [];
      // const session = vm.getSession();

      extraOptions.push(
        sharedProps.extraOptions.getEditOption(
          vm.lectureComponent.liveSession.beginningSoon()
            ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.VIEW_BASICS()
            : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
        ),
      );

      if (!vm.hasMultipleSessions()) {
        extraOptions.push({ type: 'divider' });
        if (!vm.lectureComponent.liveSession.hasEnded()) {
          extraOptions.push({
            type: 'header',
            title: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.OPTIONS_AFTER_EVENT(),
          });
        }

        extraOptions.push({
          type: 'text',
          text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.UPLOAD_ATTENDEE_LIST(),
          callback: () => vm.uploadAttendees(),
          disabled: !vm.lectureComponent.liveSession.hasEnded(),
        });

        if (vm.lectureComponent.liveSession.hasEnded()) {
          if (vm.lectureComponent.liveSession?.sessions[0]?.recording?.file) {
            extraOptions.push({
              type: 'text',
              text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.EDIT_RECORDING(),
              callback: () => vm.editRecording(),
              dataQa: config.pendo.editLiveEventRecording.editRecording,
            }, {
              type: 'text',
              text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.REMOVE_RECORDING(),
              callback: () => vm.removeRecording(),
            });
          } else {
            extraOptions.push({
              type: 'text',
              text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.UPLOAD_RECORDING(),
              callback: () => vm.uploadRecording(),
            });
          }
        } else if (!vm.lectureComponent.liveSession.hasEnded()) {
          extraOptions.push({
            type: 'text',
            text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.UPLOAD_RECORDING(),
            disabled: true,
          });
        }
      }

      setSharedProps({
        ...sharedProps,
        extraOptions: {
          ...sharedProps.extraOptions,
          renderOnMount: true,
          ...{
            options: extraOptions,
          },
        },
      });
    };

    vm.setEditMenu();

    vm.editBasics = () => {
      $timeout(() => {
        vm.context.sharedProps.openBasicModal();
      });
    };

    vm.getStatus = () => {
      const { liveSession } = vm.lectureComponent;
      if (liveSession.attendeeInfo && liveSession.isCompleted()) {
        return 'completed';
      }
      const { sessions } = liveSession;
      if (!sessions) {
        return 'not_started';
      }

      let completedSession = false;
      let missedSession = true;
      let inProgressSession = false;
      let pendingSession = false;

      sessions.forEach((session) => {
        const { status } = session.attendeeInfo || {};

        if (status === 'attended_auto'
          || status === 'attended_manual'
          || status === 'watched_recording'
        ) {
          completedSession = true;
        } else if (status === 'not_available') {
          missedSession = false;
        } else if (status === 'missed') {
          inProgressSession = false;
          pendingSession = false;
        } else if (status === 'in_progress') {
          inProgressSession = true;
          pendingSession = false;
        } else if (status === 'pending') {
          pendingSession = true;
        }
      });

      if (completedSession) {
        return 'completed';
      } if (inProgressSession) {
        return 'in_progress';
      } if (pendingSession) {
        return 'pending';
      } if (missedSession) {
        return 'missed';
      }
      return 'not_available';
    };

    vm.showJoinButton = () => {
      const { editMode } = vm;
      const { joinUrl } = vm.getSession();
      const beginningSoon = vm.lectureComponent.liveSession.beginningSoon();
      return beginningSoon && !editMode && joinUrl;
    };

    vm.showDisableButton = () => {
      const { startTime } = vm.getSession();
      const hasErrors = vm.lectureComponent.liveSession.hasErrors();
      const hasEnded = vm.lectureComponent.liveSession.hasEnded();
      const hasMultipleSessions = vm.hasMultipleSessions();
      return !hasErrors
        && startTime
        && !hasEnded
        && !hasMultipleSessions;
    };

    vm.isAboutToStart = () => {
      const { joinUrl } = vm.getSession();
      const beginningSoon = vm.lectureComponent.beginningSoon();
      return !beginningSoon || !joinUrl;
    };

    vm.showJoinUsingApp = () => {
      const { sessionType } = vm.getSession();
      const beginningSoon = vm.lectureComponent.beginningSoon();
      const hasEnded = vm.lectureComponent.liveSession.hasEnded();
      return beginningSoon
        && !hasEnded
        && sessionType === vm.lectureComponent.constructor.SESSION_TYPE_ZOOM;
    };

    vm.getSession = () => vm.lectureComponent.liveSession.sessions?.[0] ?? vm.lectureComponent.liveSession;

    vm.getSingleSessionTitle = () => vm.lectureComponent.liveSession.title ?? vm.getSession().title;

    vm.getMultiSessionTitle = () => vm.lectureComponent.liveSession.mainTitle ?? vm.getSession().title;

    vm.getSingleSessionDescription = () => vm.lectureComponent.liveSession.description ?? vm.getSession().description;

    vm.getMultiSessionDescription = () => vm.lectureComponent.liveSession.mainDescription ?? vm.getSession().description;
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-live-session-lecture-component.html',
};
