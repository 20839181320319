import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { gray6, white } from 'styles/global_defaults/colors';
import { handheld } from 'styles/global_defaults/media-queries';
import { flyoutHeight, flyoutWidth, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';

type FlyoutHeaderProps = {
  title: string;
  isSticky: boolean;
  onClose: () => void;
};

const FlyoutHeader = ({ title, isSticky, onClose }: FlyoutHeaderProps) => {
  const styles = css`
    min-width: ${flyoutWidth}px;
    min-height: ${flyoutHeight}px;
    width: inherit;
    padding: ${standardSpacing}px;
    padding-right: ${tripleSpacing}px;
    background-color: ${white};
    border-bottom: 1px solid ${gray6};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    ${isSticky && `
      position: sticky;
      top: 0;
    `}

    ${handheld(css`
      width: 100%;
    `)}

    .close-flyout {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: ${standardSpacing}px;

      &:hover {
        cursor: pointer;
      }
    }
  `;

  return (
    <div css={styles}>
      <div
        className='course-title-small font-weight-bolder text-center'
        aria-live='polite'
      >
        {title}
      </div>
      <ClickableContainer
        onClick={onClose}
        className='close-flyout'
        aria-label={t.NOVOED.CLOSE()}
      >
        <NvIcon size='small' icon='close' />
      </ClickableContainer>
    </div>
  );
};

export default FlyoutHeader;
